var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c(
            "b-tabs",
            { attrs: { pills: "" } },
            [
              _c("b-tab", {
                attrs: { title: "일자별 유입 현황" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "daily"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "진입경로별 유입 현황" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "route"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "요일별 유입 현황" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "weekday"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "시간대별 유입 현황" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "hour"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "유입 횟수" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "cycle"
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _vm.viewType === "daily"
            ? _c("daily-view")
            : _vm.viewType === "route"
            ? _c("route-view")
            : _vm.viewType === "weekday"
            ? _c("weekday-view")
            : _vm.viewType === "hour"
            ? _c("hour-view")
            : _vm.viewType === "cycle"
            ? _c("cycle-view")
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab
          title="일자별 유입 현황"
          @click="viewType = 'daily'"
        />
        <b-tab
          title="진입경로별 유입 현황"
          @click="viewType = 'route'"
        />
        <b-tab
          title="요일별 유입 현황"
          @click="viewType = 'weekday'"
        />
        <b-tab
          title="시간대별 유입 현황"
          @click="viewType = 'hour'"
        />
        <b-tab
          title="유입 횟수"
          @click="viewType = 'cycle'"
        />
      </b-tabs>
    </b-card>

    <b-card>
      <daily-view v-if="viewType === 'daily'" />
      <route-view v-else-if="viewType === 'route'" />
      <weekday-view v-else-if="viewType === 'weekday'" />
      <hour-view v-else-if="viewType === 'hour'" />
      <cycle-view v-else-if="viewType === 'cycle'" />
    </b-card>
  </div>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import DailyView from './view/DailyView.vue'
import RouteView from './view/RouteView.vue'
import WeekdayView from './view/WeekdayView.vue'
import HourView from './view/HourView.vue'
import CycleView from './view/CycleView.vue'

export default {
  components: {
    BTab,
    BTabs,
    DailyView,
    RouteView,
    WeekdayView,
    HourView,
    CycleView,
  },

  setup() {
    const viewType = ref("daily")

    return {
      viewType,
    }
  },
}
</script>
